import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from "../models/country.model";
import { City } from "../models/city.model";
import { Area } from "../models/area.model";
import { Admin } from "../models/admin.model";
import { HttpRequestsService } from "../services/utilities/http-request.service";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { superset } from "d3";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

@Injectable()

export class AdminService extends UnsubscribeOnDestroyAdapter {
  isTblLoading = true;
  dataChange: BehaviorSubject<Admin[]> = new BehaviorSubject<Admin[]>([]);
    // Temporarily stores data from dialogs
    dialogData!: Admin;

  get data(): Admin[] {
      return this.dataChange.value;
  }
  getDialogData() {
      return this.dialogData;
  }
  constructor(private httpService: HttpRequestsService,private httpClient: HttpClient) {
    super();
  }

  
  getAdminList(): void {
    this.subs.sink = this.httpClient.get<any>('administrator?schoolid=1&page=1&pagesize=10').subscribe({
      next: (data) => {
        console.log(data);
        this.isTblLoading = false;
        this.dataChange.next(data.results);
      },
      error: (error: HttpErrorResponse) => {
        this.isTblLoading = false;
        console.log(error.name + ' ' + error.message);
      },
    });
  }
  
  // getAdminList(): Observable<Admin[]> {
  //   console.log('hello');
  //   return this.httpService.getHTTPRequest('administrator?schoolid=1&page=1&pagesize=2')
  //     .pipe(map((responseData:any) => responseData));
  // }

  getAdminById(id:number): Observable<Admin> {
    return this.httpService.getHTTPRequest('administrator/'+id)
      .pipe(map((responseData: Admin) => responseData));
  }

  
  addAdmin(firstName:string,familyName:string,gender:number,userPassword:string,schoolId:number)
  {
    return this.httpService.postHTTPRequest('administrator',{firstName,familyName,gender,userPassword,schoolId})
    .pipe(map((responseData: any) => responseData));
  }


  setPassword(id:number,userPassword:string,schoolId:number)
  {
    return this.httpService.postHTTPRequest('administrator/password',{id,userPassword,schoolId})
    .pipe(map((responseData: any) => responseData));
  }

  editAdmin(input:Admin)
  {
    console.log(input);
    return this.httpService.putHTTPRequest('administrator/'+input.id,{firstName:input.firstName,familyName:input.familyName,isActive:input.isActive,gender:input.gender,schoolId:1})
    .pipe(map((responseData: any) => responseData));
  }


  setActivity(id:number,adminId:number,isActive:number,schoolId:number)
  {
    console.log(id,adminId,isActive,schoolId);
    return this.httpService.postHTTPRequest('administrator/setActivity',{id,adminId,isActive,schoolId})
    .pipe(map((responseData: any) => responseData));
  }

  revealPassword(id:number): Observable<any> {
    return this.httpService.getHTTPRequest('administrator/'+id+'/password')
      .pipe(map((responseData: any) => responseData));
  }

  deleteAdmin(id:number): Observable<any> {
    return this.httpService.deleteHTTPRequest('administrator/'+id)
      .pipe(map((responseData: any) => responseData));
  }

  sendEmail(email:string)
  {
    return this.httpService.postHTTPRequest('api/mail/contactus',email)
    .pipe(map((responseData: any) => responseData));
  }
}