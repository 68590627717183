import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from "../models/country.model";
import { City } from "../models/city.model";
import { Area } from "../models/area.model";
import { HttpRequestsService } from "../services/utilities/http-request.service";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { superset } from "d3";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Matron } from "../models/matron.model";


@Injectable()

export class MatronService extends UnsubscribeOnDestroyAdapter {
  isTblLoading = true;
  dataChange: BehaviorSubject<Matron[]> = new BehaviorSubject<Matron[]>([]);
    // Temporarily stores data from dialogs
    dialogData!: Matron;

  get data(): Matron[] {
      return this.dataChange.value;
  }
  getDialogData() {
      return this.dialogData;
  }
  constructor(private httpService: HttpRequestsService,private httpClient: HttpClient) {
    super();
  }

  
  getAllMatronsList(): Observable<any> {
      return this.httpService.postHTTPRequest('matron/getFiltered',{page:1,pagesize:10000,schoolId:1})
      .pipe(map((responseData: any) => responseData));
  }
  
  getMatronById(id:number): Observable<Matron> {
    return this.httpService.getHTTPRequest('matron/'+id)
      .pipe(map((responseData: Matron) => responseData));
  }

  
  addMatron(input:Matron)
  {
    return this.httpService.postHTTPRequest('matron',{firstName:input.firstName
      ,familyName:input.familyName,phone:input.phone,schoolId:1})
    .pipe(map((responseData: any) => responseData));
  }




  editMatron(input:Matron)
  {
    console.log(input);
    return this.httpService.putHTTPRequest('matron/'+input.id,
    {firstName:input.firstName,
    familyName:input.familyName,
    phone:input.phone,schoolId:1}
    )
    .pipe(map((responseData: any) => responseData));
  }



  deleteMatron(id:number): Observable<any> {
    return this.httpService.deleteHTTPRequest('matron/'+id)
      .pipe(map((responseData: any) => responseData));
  }

}