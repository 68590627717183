import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from "../models/country.model";
import { City } from "../models/city.model";
import { Area } from "../models/area.model";
import { HttpRequestsService } from "../services/utilities/http-request.service";
import { UnsubscribeOnDestroyAdapter } from "../shared/UnsubscribeOnDestroyAdapter";
import { superset } from "d3";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Driver } from "../models/driver.model";

@Injectable()

export class DriverService extends UnsubscribeOnDestroyAdapter {
  isTblLoading = true;
  dataChange: BehaviorSubject<Driver[]> = new BehaviorSubject<Driver[]>([]);
    // Temporarily stores data from dialogs
    dialogData!: Driver;

  get data(): Driver[] {
      return this.dataChange.value;
  }
  getDialogData() {
      return this.dialogData;
  }
  constructor(private httpService: HttpRequestsService,private httpClient: HttpClient) {
    super();
  }

  
  getAllDriversList(): Observable<any> {
      return this.httpService.postHTTPRequest('driver/getFiltered',{page:1,pagesize:10000,schoolId:1})
      .pipe(map((responseData: any) => responseData));
  }
  
  getDriverById(id:number): Observable<Driver> {
    return this.httpService.getHTTPRequest('driver/'+id)
      .pipe(map((responseData: Driver) => responseData));
  }

  
  addDriver(input:Driver)
  {
    return this.httpService.postHTTPRequest('driver',{firstName:input.firstName
      ,familyName:input.familyName,phone:input.phone,schoolId:1})
    .pipe(map((responseData: any) => responseData));
  }




  editDriver(input:Driver)
  {
    console.log(input);
    return this.httpService.putHTTPRequest('driver/'+input.id,
    {firstName:input.firstName,
    familyName:input.familyName,
    phone:input.phone,schoolId:1}
    )
    .pipe(map((responseData: any) => responseData));
  }



  deleteDriver(id:number): Observable<any> {
    return this.httpService.deleteHTTPRequest('driver/'+id)
      .pipe(map((responseData: any) => responseData));
  }

}